






















































import Vue from 'vue'

export default Vue.extend({
    name: 'Config',
    data: () => ({
        loading: false,
        alerts: [] as any[],
        admins: [],
        adminOptions: [],
        channels: [],
        debugChannel: null
    }),
    methods: {
        async save () {
            this.loading = true
            const result = await this.$http.post('config', {
                admins: this.admins,
                debugChannel: this.debugChannel

            })
            if (result.status === 200) {
                this.alerts.push({type: 'success', message: 'Saved!'})
                this.loading = false
            } else {
                this.alerts.push({type: 'error', message: 'Error while saving!'})
                this.loading = false
            }
        },
    },
    computed: {
        minOne: () => {
            return (v: []) => (v || []).length > 0 ||
                    'Need at least one entry'
        }
    },
    mounted () {
        this.$http.get('config/options')
            .then((response: any) => {
                this.adminOptions = response.data.adminOptions
            })
        this.$http.get('transformer/discord/outputchannels')
            .then((response: any) => {
                this.channels = response.data
            })
        this.$http.get('config')
            .then((response: any) => {
                this.admins = response.data.admins
                this.debugChannel = response.data.debugChannel
            })
    }
})
