





































import Vue from "vue";

export default Vue.extend({
    name: 'Home',

    components: {},

    data: () => ({
        pm2: {},
        lastUpdate: 'never'
    }),
    methods: {
        bytesToSize(bytes: any) {
            let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            let i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) as unknown as string);
            return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
        },
        getColor(status: string): string {
            switch (status) {
                case 'online':
                    return 'green'
                default:
                    return 'grey'
            }
        }
    },
    mounted () {
        this.$http.get('info/pm2')
            .then((response) => {
                this.pm2 = response.data
                this.lastUpdate = new Date().toUTCString()
            })
        setInterval(() => {
            this.$http.get('info/pm2')
                .then((response) => {
                    this.pm2 = response.data
                    this.lastUpdate = new Date().toUTCString()
                })
        }, 5000)
    }
})
