


















import Vue from 'vue'
export default Vue.extend({
    props: {
        options: Object,
        id: String
    },
    data: () => ({
        channels: []
    }),
    computed: {
        required() {
            const rules = []
            const rule =
                (v: any) => (v || '').length > 0 || `Field is required`
            rules.push(rule)
            return rules
        }

    },
    mounted () {
        this.$http.get('transformer/discord/outputchannels')
            .then((response: any) => {
                this.channels = response.data
            })
    }
})
