





























import Vue from 'vue'

export default Vue.extend({
    props: {
        options: Object,
        id: String
    },
    data: () => ({
        sourceGuild: [],
        targetChannels: []
    }),
    computed: {
        required() {
            const rules = []
            const rule =
                (v: any) => (v || '').length > 0 || `Field is required`
            rules.push(rule)
            return rules
        }

    },
    mounted () {
        this.$http.get('spy/execute/guildfilter/'+this.id)
            .then((response: any) =>{
                this.sourceGuild = response.data
            })
        this.$http.get('transformer/discord/outputchannels')
            .then((response: any) => {
                this.targetChannels = response.data
            })
    }
})
