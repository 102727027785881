


















































































































import { uuid } from 'vue-uuid'
import Vue from 'vue'
export default Vue.extend({
    name: 'Spies',

    components: {},

    data: () => ({
        headers: [
            {text: 'ID', value: '_id'},
            {text: 'Status', value: 'status'},
            {text: 'Groups', value: 'groups'},
            {text: 'Active rules', value: 'ruleCount'},
            {text: 'Has ELK Export', value: 'elkExporter'},
            {text: 'Actions', value: 'actions', sortable: false}
        ],
        spies: [] as any[],
        status: {},
        alerts: [] as any[],
        lastUpdate: 'never' as string,
        search: ''
    }),
    methods: {
        strStartsWith(haystack: string, needle: string) {
            if (typeof haystack === "undefined") return false
            return haystack.startsWith(needle)
        },
        editItem(item: any) {
            this.$router.push('/spies/'+item._id)
        },
        startSpy(item: any) {
            this.$http.get('/spy/execute/start/'+item._id)
        },
        stopSpy(item: any) {
            this.$http.get('/spy/execute/stop/'+item._id)
        },
        newItem() {
            this.$router.push('/spies/new')
        },
        exportSpies() {
            this.$http.get('spy/execute/export').then((response) => {
                this.alerts.push({_id: uuid.v4(), type: 'success', message: response.data})
            }).catch((response) => {
                this.alerts.push({_id: uuid.v4(), type: 'error', message: response.data})
            })
        },
        resetSpies() {
            this.$http.get('spy/execute/reset').then((response) => {
                this.alerts.push({_id: uuid.v4(), type: 'success', message: response.data})
            }).catch((response) => {
                this.alerts.push({_id: uuid.v4(), type: 'error', message: response.data})
            })
        }
    },
    async mounted() {
        let spies = await this.$http.get('spy');
        if (spies.status === 200) {
            this.spies = spies.data
            for (let i = 0; i < this.spies.length; i++) {
                if (typeof this.spies[i].relay !== 'undefined') {
                    this.spies[i].ruleCount = this.spies[i].relay.length
                } else {
                    this.spies[i].ruleCount = 0
                }
                if (this.spies[i].relay.find((e: any) => e.type === "ELKExporter")) {
                    this.spies[i].elkExporter = 'Yes'
                } else {
                    this.spies[i].elkExporter = 'No'
                }
            }
        } else {
            alert(spies.statusText)
        }
        this.$http.get('spy/status')
            .then((response) => {
                this.status = response.data
                this.lastUpdate = new Date().toUTCString()
            })
        setInterval(() => {
            this.$http.get('spy/status')
                .then((response) => {
                    this.status = response.data
                    this.lastUpdate = new Date().toUTCString()
                })
        }, 5000)
    }
})
