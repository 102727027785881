import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Spies from '../views/Spies.vue'
import SpiesEdit from '../views/Spies/Edit.vue'
import SpyNew from '../views/Spies/New.vue'
import Global from '../views/Global.vue'
import GlobalEdit from '../views/Global/Edit.vue'
import Config from '../views/Config.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/spies',
    name: 'Spies',
    component: Spies
  },
  {
    path: '/spies/new',
    name: 'New Spy',
    component: SpyNew
  },
  {
    path: '/spies/:id',
    name: 'Edit Spy',
    component: SpiesEdit
  },
  {
    path: '/global',
    name: 'Global Rules',
    component: Global
  },
  {
    path: '/global/:id',
    name: 'Edit global rule',
    component: GlobalEdit
  },
  {
    path: '/config',
    name: 'Config',
    component: Config
  }
]

const router = new VueRouter({
  routes
})

export default router
