










































import Vue from 'vue'
export default Vue.extend({
    name: 'Global',

    components: {},

    data: () => ({
        headers: [
            {text: 'ID', value: '_id'},
            {text: 'Name', value: 'name'},
            {text: 'Keywords', value: 'keywordCount'},
            {text: 'Actions', value: 'actions', sortable: false}
        ],
        globalRules: [] as any[],
        alerts: [] as any[]
    }),
    methods: {
        editItem(item: any) {
            this.$router.push('/global/'+item._id)
        },
        newItem() {
            this.$router.push('/global/-1')
        }
    },
    async mounted() {
        let global = await this.$http.get('global');
        if (global.status === 200) {
            this.globalRules = global.data
            for (let i = 0; i < this.globalRules.length; i++) {
                if (typeof this.globalRules[i].keywords !== 'undefined') {
                    this.globalRules[i].keywordCount = this.globalRules[i].keywords.length
                } else {
                    this.globalRules[i].keywordCount = 0
                }
            }
        } else {
            alert(global.statusText)
        }
    }
})
