









import Vue from 'vue'
export default Vue.extend({
    props: {
        options: Object,
        id: String
    },
    data: () => ({
    }),
    computed: {

    },
    mounted () {

    }
})
