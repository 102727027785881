



















































































































import Vue from 'vue'
export default Vue.extend({
    data: () => ({
        id: null,
        globalName: '',
        targetChannels: [],
        target: [],
        keywords: [],
        loading: false,
        showDeleteDialog: false,
        apply: [],
        applyOptions: []
    }),
    computed: {
        required() {
            const rules = []
            const rule =
                (v: any) => (v || '').length > 0 || `Field is required`
            rules.push(rule)
            return rules
        }

    },
    methods: {
        async save () {
            this.loading = true
            const result = await this.$http.post('global', {
                id: this.id,
                name: this.globalName,
                apply: this.apply,
                keywords: this.keywords,
                target: this.target

            })
            if (result.status === 200) {
                this.$router.push('/global')
            } else {
                this.loading = false
                alert(result.statusText)
            }
        },
        async remove() {
            this.showDeleteDialog = false
            this.loading = true
            //@ts-ignore $http is getting initialized on main.ts
            const result = await this.$http.delete('global/'+this.id)
            if (result.status === 200) {
                this.$router.push('/global')
            } else {
                this.loading = false
                alert(result.statusText)
            }
        }
    },
    mounted () {
        this.$http.get('global/execute/applies')
            .then((response: any) => {
                this.applyOptions = response.data
            })
        this.$http.get('transformer/discord/outputchannels')
            .then((response: any) => {
                this.targetChannels = response.data
            })
        let id = this.$route.params.id
        if (id !== "-1") {
            this.$http.get(`global/${id}`)
                .then((response: any) => {
                    this.id = response.data._id
                    this.globalName = response.data.name
                    this.apply = response.data.apply
                    this.keywords = response.data.keywords
                    this.target = response.data.target
                })
        }
    }
})
