























































import Vue from 'vue'
import draggable from 'vuedraggable'
export default Vue.extend({
    props: {
        options: Object,
        id: String
    },
    components: {
        draggable
    },
    data: () => ({
        ruleType: [
            {
                value: 'sourceChannels',
                label: 'Source Channel is'
            },
            {
                value: 'message',
                label: 'Message contains'
            }
        ],
        messageOptions: [
            '@everyone',
            '@here',
            'ping'
        ],
        sourceChannels: []
    }),
    computed: {
        required() {
            const rules = []
            const rule =
                (v: any) => (v || '').length > 0 || `Field is required`
            rules.push(rule)
            return rules
        }

    },
    methods: {
        removeRule(id: string) {
            let index = this.options.transformers.findIndex((item: any) => item._id === id)
            if (index !== -1) {
                this.options.transformers.splice(index, 1)
            }
        }
    },
    mounted () {
        this.$http.get('spy/execute/filter/'+this.id)
            .then((response: any) =>{
                this.sourceChannels = response.data
            })
    }
})
