














































































































































































































import Vue from 'vue'
import draggable from 'vuedraggable'
import { uuid } from 'vue-uuid'
import TargetChannelsAssociator from '@/components/Transformer/TargetChannelsAssociator.vue'
import ChannelsToChannelsAssociator from '@/components/Transformer/ChannelsToChannelsAssociator.vue'
import GuildToChannelsAssociator from '@/components/Transformer/GuildToChannelsAssociator.vue'
import MessageToEmbedTransformer from '@/components/Transformer/MessageToEmbedTransformer.vue'
import MessageToStringTransformer from '@/components/Transformer/MessageToStringTransformer.vue'
import ConditionTransformer from '@/components/Transformer/ConditionTransformer.vue'
import ELKExporter from '@/components/Transformer/ELKExporter.vue'
import DMHandleTransformer from '@/components/Transformer/DMHandleTransformer.vue'

export default Vue.extend({
    name: 'SpiesEdit',
    components: {
        draggable
    },

    data: () => ({
        addRuleTo: null,
        tab: null,
        idLength: 10,
        inputTypes: [
            { label: 'Discord', value: 'discord', disabled: false },
            { label: 'Slack', value: 'slack', disabled: true },
            { label: 'XMPP', value: 'xmpp', disabled: true }
        ],
        outputTypes: [
            { label: 'Socket', value: 'socket' },
            { label: 'Webhook', value: 'webhook' }
        ],
        id: '',
        input: '',
        inputConfig: {},
        inputConfigDisabled: true,
        output: '',
        outputConfig: {},
        outputConfigDisabled: true,
        discordConfig: {
            token: ""
        },
        loading: false,
        showDeleteDialog: false,
        showAddRule: false,
        rules: [] as any,
        filters: [
            {
                name: 'TargetChannelsAssociator',
                desc: 'This transformer assigns all messages to a target channel',
                component: TargetChannelsAssociator
            },
            {
                name: 'GuildToChannelsAssociator',
                desc: 'This transformer assigns all messages from a specific guild to a target channel',
                component: GuildToChannelsAssociator
            },
            {
                name: 'ChannelsToChannelsAssociator',
                desc: 'This transformer assigns all messages from a specific channels to a target channel',
                component: ChannelsToChannelsAssociator
            },
            {
                name: 'ConditionTransformer',
                desc: 'This transformer allows to set conditions for other transformers to apply',
                component: ConditionTransformer
            },
            {
                name: 'MessageToEmbedTransformer',
                desc: 'This transformer defines the actually message to be sent. It will be displayed in a embed. This should be considered outdated as it conflicts with other events and embeds inside the original message',
                component: MessageToEmbedTransformer
            },
            {
                name: 'MessageToStringTransformer',
                desc: 'This transformer defines the actually message to be sent. it will be displayed as a simple string.',
                component: MessageToStringTransformer
            },
            {
                name: 'ELKExporter',
                desc: 'Exports the message object to the ELK stack for further analysis.',
                component: ELKExporter
            },
            {
                name: 'DMHandleTransformer',
                desc: 'Handles DMs to be sent to a dedicated channel for better tracking',
                component: DMHandleTransformer
            }
        ],
        rerenderKey: 0,
        groupOptions: [],
        groups: [],
        enabled: true
    }),
    computed: {
        idRules () {
            const rules = []
            rules.push(((v: string) => (v || '').length === this.idLength ||
                `ID needs to be exactly ${this.idLength} characters long.`))
            rules.push(((v: string) => (v || '').indexOf(' ') < 0 ||
                'No spaces are allowed'))
            return rules
        },
        notEmptyRule () {
            const rules = []
            rules.push(((v: string) => (v || '') !== '' ||
                `May not be empty`))
            return rules
        },
        subRules () {
            let subrules = [{ value: null, label: 'Root' }];
            for (let i = 0; i < this.rules.length; i++) {
                let rule = this.rules[i]
                if (rule.type === 'ConditionTransformer') {
                    subrules.push({value: rule._id, label: rule.name})
                }
            }
            return subrules
        }

    },
    methods: {
        inputTypeChange () {
            if (this.input === 'discord') {
                this.inputConfigDisabled = false
            }
        },
        leave () {
            this.$router.push('/spies')
        },
        async save () {
            this.loading = true
            const result = await this.$http.post('spy', {
                id: this.id,
                groups: this.groups,
                enabled: this.enabled,
                spy: {
                    input: {
                        code: this.input,
                        options: this.inputConfig
                    },
                    transformer: [],
                    output: {
                        code: this.output,
                        options: this.outputConfig
                    }
                },
                relay: this.gatherOptions()
            })
            if (result.status === 200) {
                this.$router.push('/spies')
            } else {
                this.loading = false
                alert(result.statusText)
            }
        },
        async remove() {
            this.showDeleteDialog = false
            this.loading = true
            //@ts-ignore $http is getting initialized on main.ts
            const result = await this.$http.delete('spy/'+this.id)
            if (result.status === 200) {
                this.$router.push('/spies')
            } else {
                this.loading = false
                alert(result.statusText)
            }
        },
        addFilter(filter: {name: string, desc: string, component: any}, parentId: string|null = null, id: string|null = null, name: string|null = null, options: any = {}) {
            //@ts-ignore rules is an array of objects
            if (id === null) {
                id = uuid.v4()
            }
            if (name === null) {
                name = filter.name
            }
            if (parentId === null) {
                this.rules.push({_id: id, type: filter.name,  name: name, component: filter.component, options: options})
                this.rerenderKey += 1
            } else {
                if (typeof this.rules.find((e: any) => e._id === parentId).options.transformers === "undefined") {
                    this.rules.find((e: any) => e._id === parentId).options.transformers = []
                }
                this.rules.find((e: any) => e._id === parentId).options.transformers.push({_id: id, type: filter.name,  name: name, component: filter.component, options: options});
                this.rerenderKey += 1
            }
            this.showAddRule = false
        },
        removeRule(id: string) {
            let index = this.rules.findIndex((item: any) => item._id === id)
            if (index !== -1) {
                this.rules.splice(index, 1)
            }
        },
        gatherOptions(): any {
            let options = []
            for (let i = 0; i < this.rules.length; i++) {
                let rule: { _id: string, type: string, name: string, component: any, options: any } = this.rules[i]
                options.push({_id: rule._id, type: rule.type, name: rule.name, options: rule.options})
            }
            return options;
        }
    },
    async mounted() {
        let groupOptions = await this.$http.get('spy/execute/groups')
        this.groupOptions = groupOptions.data
        const id = this.$route.params.id;
        let spyEntity: any = await this.$http.get('spy/'+id)
        if (spyEntity.status === 200) {
            spyEntity = spyEntity.data
        } else {
            alert(spyEntity.statusText)
            return
        }
        this.id = spyEntity._id
        this.input = spyEntity.spy.input.code
        this.inputConfig = spyEntity.spy.input.options
        this.output = spyEntity.spy.output.code
        this.outputConfig = spyEntity.spy.output.options
        this.groups = spyEntity.groups
        this.enabled = spyEntity.enabled
        for (let i = 0; i < spyEntity.relay.length; i++) {
            let rule: {_id: string, name: string, type: string, options: any} = spyEntity.relay[i]
            let filter: any = this.filters.find((item) => item.name === rule.type)
            this.addFilter(filter, null, rule._id, rule.name, rule.options)
        }
        for (let i = 0; i < this.rules.length; i++) {
            let rule = this.rules[i]
            if (rule.type === 'ConditionTransformer') {
                for (let j = 0; j < this.rules[i].options.transformers.length; j++) {
                    let filter: any = this.filters.find((item) => item.name === this.rules[i].options.transformers[j].type)
                    this.rules[i].options.transformers[j].component = filter.component
                }
            }
        }
    }
})
