








































































































import Vue from 'vue'

export default Vue.extend({
    name: 'SpyNew',
    components: {},

    data: () => ({
        tab: null,
        idLength: 10,
        inputTypes: [
            { label: 'Discord', value: 'discord', disabled: false },
            { label: 'Slack', value: 'slack', disabled: true },
            { label: 'XMPP', value: 'xmpp', disabled: true }
        ],
        outputTypes: [
            { label: 'Socket', value: 'socket' },
            { label: 'Webhook', value: 'webhook' }
        ],
        id: '',
        input: '',
        inputConfig: {},
        inputConfigDisabled: true,
        output: '',
        outputConfig: {},
        outputConfigDisabled: true,
        discordConfig: {
            token: ""
        },
        loading: false,
        groupOptions: [],
        groups: [],
        enabled: true
    }),
    computed: {
        idRules () {
            const rules = []
            rules.push(((v: string) => (v || '').length === this.idLength ||
                `ID needs to be exactly ${this.idLength} characters long.`))
            rules.push(((v: string) => (v || '').indexOf(' ') < 0 ||
                'No spaces are allowed'))
            return rules
        },
        notEmptyRule () {
            const rules = []
            rules.push(((v: string) => (v || '') !== '' ||
                `May not be empty`))
            return rules
        }
    },
    methods: {
        inputTypeChange () {
            if (this.input === 'discord') {
                this.inputConfigDisabled = false
            }
        },
        leave () {
            this.$router.push('/spies')
        },
        async save () {
            this.loading = true
            //@ts-ignore $http is getting initialized on main.ts
            const result = await this.$http.post('spy', {
                id: this.id,
                groups: this.groups,
                enabled: this.enabled,
                spy: {
                    input: {
                        code: this.input,
                        options: this.inputConfig
                    },
                    transformer: [],
                    output: {
                        code: this.output,
                        options: this.outputConfig
                    }
                },
                relay: {}
            })
            if (result.status === 200) {
                this.$router.push('/spies')
            } else {
                this.loading = false
                alert(result.statusText)
            }
        }
    },
    async mounted() {
        let groupOptions = await this.$http.get('spy/execute/groups')
        this.groupOptions = groupOptions.data
    }
})
